const zh_CN = {
  Category_analysis: '品类分析',
  Product_analysis: '商品分析',
  Crowd_analysis: '人群分析',
  Channel_research: '渠道研究',
  Search: ' 查找',
  OverallMarket_Trends: '品类大盘趋势',
  CategoryBuzz_Analysis: '品类热度分析',
  Attribute_Analysis: '品类属性分析',
  CategoryPopularity_Landscape: '市场热度格局',
  SequentialGrowth_Index: '环比涨幅',
  Emerging_FastGrowing: '机会趋势',
  Big_Trending: '火热趋势',
  Low_Signal: '未来趋势',
  Big_Stable: '均衡趋势',
  ProductBuzz_Trend: '热度趋势',
  Category: '品类',
  Brand: '品牌',
  Time: '时间',
  Channel: '渠道',
  Wrapper: '皮',
  Mouthfeel: '口感',
  Taste: '味道',
  Category_Buzz: '品类热度',
  Brand_Buzz: '品牌热度',
  CategoryBuzz_Trend: '品类热度趋势',
  ProductTrend_Analysis: '商品热度四象限',
  Trending_Index: '趋势指数',
  LowBuzz_HighGrowth: '低热度&高趋势',
  HighBuzz_HighGrowth: '高热度&高趋势',
  LowBuzz_LowGrowth: '低热度&低趋势',
  HighBuzz_LowGrowth: '高热度&低趋势',
  Top10Productsby_Buzz: '商品热度top10',
  CategoryWordCloud: '品类词云',
  CookingMethods: '烹饪方式',
  Price: '价格',
  ProductAttribute_Sentiment: '属性情感',
  AttributeSentiment_Trend: '属性情感趋势',
  ProductAttribute_Buzz: '属性热度',
  ProductAttributeBuzz_Trend: '属性热度趋势',
  ProductAttributeTrend_Analysis: '属性热度四象限',
  Top10Fillings_byBuzz: '配料热度top10',
  OccasionWordCloud: '场景词云',
  FilteredList: '已筛选条件',
  keyword: '关键词',
  xx_Find: '共找到',
  xx_Products:'件商品',
  Add_Brand_Tips: '您已经添加{total}/2个品牌',
  Discussion: '热度',
  Add_to_Compare: '加入对比',
  Compare: '对比',
  ReferencePrice: '参考价格',
  ViewConsumerVerbatim: '查看原帖',
  ProductBuzz_GrowthTrend: '热度&环比趋势',
  ProductWordCloud: '相关词云',
  ProductCompare: '商品对比',
  ProductInfo: '基本信息',
  ProductBuzzTrend_Comparison: '热度走势对比',
  ProductBuzzGrowthTrend_Comparison: '热度环比走势对比',
  ProductAttributeSentiment_Comparison: '属性情感对比',
  ProductAttributeBuzz_Comparison: '属性热度对比',
  ProductWordCloud_Comparison: '词云对比',
  Proportionofassociation: '关联占比',
  Proportion_emotions: '情感占比',
  //
  Age: '年龄',
  Gender: '性别',
  City: '城市(全部)',
  Male: '男',
  Female: '女',
  Analyze: ' 分析',
  SelectAudienceProfile_Analysis: '所选人群的基本信息分析',
  Gender_proportion: '性别比例',
  Age_distribution: '年龄分布',
  InterestAnd_Lifestyle: '兴趣词云',
  CityTiers: '地域分布',
  SelectAudiencePreference_Analysis: '所选人群半年内的数据分析',
  Top_discussedCategory: '关注品类占比',
  Top10Product_byBuzz: '关注商品TOP10',
  Top_discussedBrands: '关注品牌占比',
  Top10Fillings_byBuzz: '关注配料top10',
  Top_discussedCookingMethods: '烹饪方式占比',
  Top_discussedOccasions: '使用场景占比',
  SamsClubBuzz_Trend: '山姆热度趋势',
  RelatedWordCloud: '相关词云',
}
export default zh_CN
