import styled from "@emotion/styled";
import React from "react";
import { Spin, Alert, Button } from "antd";
const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
export const LoadingPage = () => {
  return (
    <CenterDiv>
      <Spin size={"large"} />
    </CenterDiv>
  );
};

export const FallbackPage = ({ error }) => {
  return (
    <Alert message="Error" description={error.message} type="error" showIcon />
  );
};

export const FallbackResetPage = ({ error, resetErrorBoundary }) => {
  return (
    <Alert
      message="Error Text"
      showIcon
      description={error.message}
      type="error"
      action={
        <Button size="small" onClick={resetErrorBoundary} danger>
          重试
        </Button>
      }
    />
  );
};
