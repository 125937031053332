import axios from "axios";
import { HTTP_URL } from "./url.js";
import storage from "sweet-storage";
import { message } from "antd";

message.config({
  maxCount: 1,
});

//重新请求token
function refreshToken() {
  // instance是当前已创建的axios实例
  return instance
    .get("/auth/refreshToken?refreshToken=" + storage.get("refreshToken"))
    .then((res) => res.data);
}

// 创建一个axios实例
const instance = axios.create({
  baseURL: HTTP_URL,
  timeout: 30000,
  withCredentials: true, // 允许携带token ,这个是解决跨域产生的相关问题
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = "Bearer " + storage.get("token");
    const language = storage.get("locale");
    if (token) {
      config.headers.Authorization = token;
    }
    if (language) {
      config.headers.Language = language;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 给实例添加一个setToken方法，用于登录后将最新token动态添加到header，同时将token保存在localStorage中
instance.setToken = (token, refreshToken) => {
  instance.defaults.headers["Authorization"] = token;
  storage.save("token", token);
  storage.save("refreshToken", refreshToken);
};

// 是否正在刷新的标记
let isRefreshing = false;
// 重试队列，每一项将是一个待执行的函数形式
let requests = [];

instance.interceptors.response.use(
  (response) => {
    const { code } = response.data;
    if (code === 2005) {
      //需要重新请求token
      const config = response.config;
      if (!isRefreshing) {
        isRefreshing = true;
        return refreshToken()
          .then((res) => {
            if (res.code === 2006) {
              message.error("后端刷新授权失败");
            } else {
              const { token, refreshToken } = res.data;
              instance.setToken(token, refreshToken);
              config.headers["Authorization"] = token;
              // config.baseURL = ''
              // 已经刷新了token，将所有队列中的请求进行重试
              requests.forEach((cb) => cb(token));
              requests = [];
              return instance(config);
            }
          })
          .catch((res) => {
            //window.location.href = "/";
            message.error("后端刷新授权失败");
          })
          .finally(() => {
            isRefreshing = false;
          });
      } else {
        // 正在刷新token，将返回一个未执行resolve的promise
        return new Promise((resolve) => {
          // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
          requests.push((token) => {
            // config.baseURL = ''
            config.headers["Authorization"] = token;
            resolve(instance(config));
          });
        });
      }
    } else if (code === 2004) {
      //无法再次请求token  回到首页重新登录
      // window.location = "/";
      message.error("后端授权失败");
      // storage.clear();
    } else if (code === 500) {
      message.error(response.data.message);
      return;
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const http = {
  get: function (url, params, responseType = "json") {
    return new Promise((resolve, reject) => {
      instance
        .get(url, { params }, { responseType })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  post: function (url, params) {
    return new Promise((resolve, reject) => {
      instance
        .post(url, JSON.stringify(params))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  put: function (url, params) {
    return new Promise((resolve, reject) => {
      instance
        .put(url, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  delete: function (url, params) {
    return new Promise((resolve, reject) => {
      instance
        .delete(url + params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default http;
