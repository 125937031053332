import React, { useState, useEffect, createContext, useCallback } from 'react'
import './authConfig'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import storage from 'sweet-storage'
import {
  useMsal,
  useIsAuthenticated,
  AuthenticatedTemplate,
} from '@azure/msal-react'
import { ConfigProvider } from 'antd'
import ReactGA from 'react-ga4'
import zh_cn from 'antd/locale/zh_CN'
import eng from 'antd/locale/en_US'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { loginRequest } from './authConfig'
import http from './utils/http'
import { IntlProvider } from 'react-intl' /* react-intl imports */
import zh_CN from './static/locale/zh-CN'
import en_US from './static/locale/en-US'
import './App.css'
import Main from './pages/Home'
import GoogleAnalytics from './utils/GoogleAnalytics'
export const myContext = createContext({
  languageChange: () => {},
  locale: '',
  token: '',
  brandList: [],
  classifyList: [],
})

function App() {
  const [locale, setLocale] = useState('')
  const [token, setToken] = useState(storage.get('token'))
  const [timerId, setTimerId] = useState(null)

  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  // 品牌
  const [brandList, setBrandList] = useState([])
  const [classifyList, setClassifyList] = useState([])
  // const [cookingList, setCookingList] = useState([])
  // const [priceList, setPriceList] = useState([])

  const getToken = () => {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        let name = accounts[0].name
        ReactGA.gtag('set', 'user_properties', {
          user_email: name,
          user_Id: name,
        })
        storage.save('accessToken', response.accessToken)
        http
          .get('/auth/token?accessToken=' + response.accessToken)
          .then((res) => {
            if (res.code === 200) {
              storage.save('token', res.data.token)
              storage.save('refreshToken', res.data.refreshToken)
              setToken({ token: res.data.token })
              loadSelectData()
            }
          })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // useEffect(() => {
  //   const id = setInterval(() => {
  //     getToken();
  //     // 定时器执行的代码
  //   }, 600000);
  //   setTimerId(id);
  //   return () => {
  //     clearTimeout(timerId); // 组件卸载时清除定时器
  //   };
  // }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      instance
        .handleRedirectPromise()
        .then((tokenResponse) => {
          if (!tokenResponse) {
            const accounts = instance.getAllAccounts()

            if (accounts.length === 0) {
              //开始登陆
              instance.loginRedirect(loginRequest)
            } else {
              getToken()
            }
          } else {
            storage.save('accessToken', tokenResponse.accessToken)
            instance.setActiveAccount(instance.getAllAccounts()[0])
            let name = instance.getAllAccounts()[0].name
            ReactGA.gtag('set', 'user_properties', {
              user_email: name,
              user_Id: name,
            })

            http
              .get('/auth/token?accessToken=' + tokenResponse.accessToken)
              .then((res) => {
                if (res.code === 200) {
                  storage.save('token', res.data.token)
                  storage.save('refreshToken', res.data.refreshToken)
                  setToken({ token: res.data.token })
                  loadSelectData()
                }
              })
          }
        })
        .catch((err) => {
          // Handle error
          console.error(err)
        })
      // }
    } else {
      console.log('8888')
    }
  }, [])
  useEffect(() => {
    let lang = ''
    if (localStorage.getItem('locale')) {
      lang = localStorage.getItem('locale')
    } else {
      // lang = (navigator.language || navigator.userLanguage).substr(0, 2) //常规浏览器语言和IE浏览器
      lang = 'zh'
      localStorage.setItem('locale', lang)
    }

    if (lang === 'zh') {
      setLocale('zh')
      // moment.locale('zh-cn')
    } else {
      setLocale('en')
      // moment.locale('en')
    }
  }, [])
  // 品牌
  const loadSelectData = () => {
    // 品牌
    http.get('/api/brand/all').then((res) => {
      setBrandList(res.data)
    })
    // 品类
    http.get('/api/tree/1').then((res) => {
      setClassifyList(res.data)
    })
    // // 烹饪
    // http.get('/api/tree/26').then((res) => {
    //   setCookingList(res.data)
    // })
    // // 价格
    // http.get('/api/tree/32').then((res) => {
    //   setPriceList(res.data)
    // })
  }

  const languageChange = (lang) => {
    setLocale(lang)
    localStorage.setItem('locale', lang)
  }

  const contextVal = {
    languageChange,
    locale,
    token,
    brandList,
    classifyList,
  }

  // let messages = {};
  // messages["en"] = en_US;
  // messages["zh"] = zh_CN;

  const messages = {
    en: en_US,
    zh: zh_CN,
  }

  return (
    <div className="App">
      <IntlProvider
        locale={locale === 'zh' ? 'zh' : 'en'}
        messages={messages[locale]}
      >
        <ConfigProvider locale={locale === 'zh' ? zh_cn : eng}>
          <myContext.Provider value={contextVal}>
            <AuthenticatedTemplate>
              {token ? (
                <Router>
                  <GoogleAnalytics>
                    <Routes>
                      {/* <Route path="/" element={<PrivateRoute authed={true} />}> */}
                      <Route path="/" element={<Navigate to="/home" />} />
                      <Route path="/*" element={<Main />} />
                      {/* </Route> */}
                    </Routes>
                  </GoogleAnalytics>
                </Router>
              ) : (
                <div></div>
              )}
            </AuthenticatedTemplate>
          </myContext.Provider>
        </ConfigProvider>
      </IntlProvider>
    </div>
  )
}

export default App
