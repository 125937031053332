import React, { useState, Suspense, useContext } from 'react'
import { Layout, Menu, Button, Select } from 'antd'
import { Routes, Route, Link, useLocation } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { LoadingPage } from '../components/tools-page'
import { FormattedMessage } from 'react-intl'
import './home.css'
import HeaderLogo from '../static/image/headerLogo.png'
import Logout from '../static/image/logout.png'
import Icon1 from '../static/image/icon1.png'
import Icon2 from '../static/image/icon2.png'
import Icon3 from '../static/image/icon3.png'
import Icon4 from '../static/image/icon4.png'

import { myContext } from '../App'

const Main = React.lazy(() => import('./main/index'))

const CategoryAnalysis = React.lazy(() => import('./categoryAnalysis/index'))
const RankAnalysis = React.lazy(() => import('./rankAnalysis/index'))
const ProductAnalysis = React.lazy(() => import('./productAnalysis'))
const ProductDetail = React.lazy(() => import('./productDetail/productDetail'))
const ProductContrast = React.lazy(() => import('./productContrast/index'))
const CrowdAnalysis = React.lazy(() => import('./crowdAnalysis'))
const ChannelResearch = React.lazy(() => import('./channelResearch'))

const { Header, Content, Footer } = Layout

const items = [
  {
    label: (
      <Link to="categoryAnalysis">
        {/* <span>品类分析</span> */}
        <FormattedMessage id="Category_analysis" />
      </Link>
    ),
    key: '/categoryAnalysis',
    icon: <img className="menuIcon" src={Icon1} />,
  },
  {
    label: (
      <Link to="productAnalysis">
        {/* <span>商品分析</span> */}
        <FormattedMessage id="Product_analysis" />
      </Link>
    ),
    key: '/productAnalysis',
    icon: <img className="menuIcon" src={Icon2} />,
  },
  {
    label: (
      <Link to="crowdAnalysis">
        {/* <span>人群分析</span> */}
        <FormattedMessage id="Crowd_analysis" />
      </Link>
    ),
    key: '/crowdAnalysis',
    icon: <img className="menuIcon" src={Icon3} />,
  },
  {
    label: (
      <Link to="channelResearch">
        {/* <span>渠道研究</span> */}
        <FormattedMessage id="Channel_research" />
      </Link>
    ),
    key: '/channelResearch',
    icon: <img className="menuIcon" src={Icon4} />,
  },
]

const Home = () => {
  const location = useLocation()
  const { instance, accounts } = useMsal()
  const auth = useContext(myContext)
  const [language, setLanguage] = useState(
    auth.locale === 'zh' ? '中文' : 'English',
  )
  // console.log(auth)
  // 中英文
  // const languageChange = () => {
  //   let lang = ''
  //   if (auth.locale === 'zh') {
  //     lang = 'en'
  //   } else {
  //     lang = 'zh'
  //   }
  //   auth.languageChange(lang)
  // }
  const languageChange = (e) => {
    let lang = ''
    // const name = e === 'zh' ? '中文' : 'English'
    // if (language === name) {
    //   return
    // }
    if (auth.locale === 'zh') {
      lang = 'en'
      // setLanguage('English')
    } else {
      lang = 'zh'
      // setLanguage('中文')
    }
    auth.languageChange(lang)
  }

  // 退出
  const handleLogout = () => {
    instance
      .logoutRedirect({
        postLogoutRedirectUri: '/',
      })
      .then((e) => {
        console.log('退出成功')
      })
  }
  return (
    <Layout>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className="header-logo">
          <Link to="/home" className="header-logo">
            <img src={HeaderLogo} alt="logo" />
          </Link>
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          items={items}
          defaultSelectedKeys={['/home']}
          selectedKeys={[location.pathname]}
          style={{
            flex: 1,
            minWidth: 0,
          }}
        ></Menu>

        {/* 中英文 */}
        <div className="headerLanguage">
          {/* <Select
            // defaultValue="中文版"
            value={language}
            style={{
              width: 120,
            }}
            onChange={languageChange}
            options={[
              {
                value: 'zh',
                label: '中文',
              },
              {
                value: 'en',
                label: 'English',
              },
            ]}
          /> */}
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: 10 }}
            onClick={languageChange}
          >
            {auth.locale === 'zh' ? 'English' : '中文'}
          </Button>
        </div>
        {/* name */}
        <div style={{ color: 'rgba(255, 255, 255, 0.9)', margin: '0 20px' }}>
          {accounts && accounts.length > 0 ? accounts[0].name : ''}
        </div>
        {/* 退出 */}
        <div
          className="headerLogout"
          onClick={() => {
            handleLogout()
          }}
        >
          <img src={Logout} alt="logo" />
          {auth.locale === 'zh' ? '退出' : 'Exit'}
        </div>
      </Header>
      <div
        style={{
          minHeight: '100vh',
          background: '#ffffff',
        }}
      >
        <Content>
          <Routes>
            <Route
              exact
              path="home"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <Main />
                </Suspense>
              }
            />
            <Route
              path={'/rank-analysis/:id'}
              element={
                <Suspense fallback={<LoadingPage />}>
                  <RankAnalysis />
                </Suspense>
              }
            />
            <Route
              path="categoryAnalysis"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <CategoryAnalysis />
                </Suspense>
              }
            ></Route>
            <Route
              path="productAnalysis"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <ProductAnalysis />
                </Suspense>
              }
            ></Route>
            <Route
              path={'/detail/:id'}
              element={
                <Suspense fallback={<LoadingPage />}>
                  <ProductDetail />
                </Suspense>
              }
            />
            <Route
              path={'/contrast/:id'}
              element={
                <Suspense fallback={<LoadingPage />}>
                  <ProductContrast />
                </Suspense>
              }
            />
            <Route
              path="crowdAnalysis"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <CrowdAnalysis />
                </Suspense>
              }
            ></Route>
            <Route
              path="channelResearch"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <ChannelResearch />
                </Suspense>
              }
            ></Route>
          </Routes>
        </Content>
      </div>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        Copyright © 2024 General Mills Inc. & Lavector Technologies Limited. All
        rights reserved 浙ICP备15043091号- 1 浙公网安备 33011802000199号
      </Footer>
    </Layout>
  )
}
export default Home
