import ReactGA from "react-ga4";

const TRACKING_ID = "G-V6Z8DBXDJT";

function init() {
  console.log("init---------");
  // Enable debug mode on the local development environment
  const isDev =
    !process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === "development";
  //ReactGA.initialize(TRACKING_ID, { debug: isDev, siteSpeedSampleRate: 100 })

  ReactGA.initialize(TRACKING_ID, { gtagOptions: { debug_mode: isDev } });
}

function sendEvent(payload) {
  ReactGA.event(payload);
}

function sendPageview(path) {
  ReactGA.set({ page: path });
  ReactGA.send({ hitType: "pageview", page: path });
}

export default {
  init,
  sendEvent,
  sendPageview,
};
