const en_US = {
  Category_analysis: 'Category',
  Product_analysis: 'Product ',
  Crowd_analysis: 'Audience ',
  Channel_research: 'Sam’s Club',
  Search: ' Search',
  OverallMarket_Trends: 'Overall Market Trends',
  CategoryBuzz_Analysis: 'Category Buzz Analysis',
  Attribute_Analysis: 'Attribute Analysis',
  CategoryPopularity_Landscape: 'Category Popularity Landscape',
  SequentialGrowth_Index: 'Sequential Growth Index',
  Emerging_FastGrowing: 'Emerging & Fast Growing',
  Big_Trending: 'Big & Trending',
  Low_Signal: 'Low Signal',
  Big_Stable: 'Big & Stable',
  ProductBuzz_Trend: 'Product Buzz Trend',
  Category: 'Category',
  Brand: 'Brand',
  Time: 'Time',
  Channel: 'Channel',
  Wrapper: 'Wrapper',
  Mouthfeel: 'Mouthfeel',
  Taste: 'Taste',
  Category_Buzz: 'Category Buzz',
  Brand_Buzz: 'Brand Buzz',
  CategoryBuzz_Trend: 'Category Buzz Trend',
  ProductTrend_Analysis: 'Product Trend Analysis',
  Trending_Index: 'Trending Index',
  LowBuzz_HighGrowth: 'Low Buzz & High Growth',
  HighBuzz_HighGrowth: 'High Buzz & High Growth',
  LowBuzz_LowGrowth: 'Low Buzz & Low Growth',
  HighBuzz_LowGrowth: 'High Buzz & Low Growth',
  Top10Productsby_Buzz: 'Top 10 Products by Buzz',
  CategoryWordCloud: 'Category Word Cloud',
  CookingMethods: 'Cooking Methods',
  Price: 'Price',
  ProductAttribute_Sentiment: 'Product Attribute Sentiment',
  AttributeSentiment_Trend: 'Attribute Sentiment Trend',
  ProductAttribute_Buzz: 'Product Attribute Buzz',
  ProductAttributeBuzz_Trend: 'Product Attribute Buzz Trend',
  ProductAttributeTrend_Analysis: 'Product Attribute Trend Analysis',
  Top10Fillings_byBuzz: 'Top 10 Fillings by Buzz',
  OccasionWordCloud: 'Occasion Word Cloud',
  FilteredList: 'Filtered List',
  keyword: 'keyword',
  xx_Find: 'Find',
  xx_Products:'Products',
  Add_Brand_Tips: 'You’ve selected {total}/2 products',
  Discussion: 'Discussion',
  Add_to_Compare: 'Add to Compare',
  Compare: 'Compare',
  ReferencePrice: 'Reference Price',
  ViewConsumerVerbatim: 'View Consumer Verbatim',
  ProductBuzz_GrowthTrend: 'Product Buzz And Growth Trend',
  ProductWordCloud: 'Product Word Cloud',
  ProductCompare: 'Product Compare',
  ProductInfo: 'Product Info',
  ProductBuzzTrend_Comparison: 'Product Buzz Trend Comparison',
  ProductBuzzGrowthTrend_Comparison: 'Product Buzz Growth Trend Comparison',
  ProductAttributeSentiment_Comparison:
    'Product Attribute Sentiment Comparison',
  ProductAttributeBuzz_Comparison: 'Product Attribute Buzz Comparison',
  ProductWordCloud_Comparison: 'Product Word Cloud Comparison',
  Proportionofassociation: 'Proportion of association',
  Proportion_emotions: 'Proportion of emotions',
  //
  Age: 'Age',
  Gender: 'Gender',
  City: 'City(All)',
  Male: 'Male',
  Female: 'Female',
  Analyze: ' Analyze',
  SelectAudienceProfile_Analysis: 'Select Audience Profile Analysis',
  Gender_proportion: 'Gender',
  Age_distribution: 'Age',
  InterestAnd_Lifestyle: 'Interest And Lifestyle',
  CityTiers: 'City Tiers',
  SelectAudiencePreference_Analysis: 'Select Audience Preference Analysis',
  Top_discussedCategory: 'Top-discussed Category',
  Top10Product_byBuzz: 'Top 10 Product by Buzz',
  Top_discussedBrands: 'Top-discussed Brands',
  Top10Fillings_byBuzz: 'Top 10 Fillings by Buzz',
  Top_discussedCookingMethods: 'Top-discussed Cooking Methods',
  Top_discussedOccasions: 'Top-discussed Occasions',
  SamsClubBuzz_Trend: 'Sam’s Club Buzz Trend',
  RelatedWordCloud: 'Related Word Cloud',
}
export default en_US
